<template>
    <div class="loadingio-spinner-spinner-yzwtdxlov3"><div class="ldio-idanrvjiwra">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
</template>

<script>
    export default {
        name: "Spinner"
    }
</script>

<style type="text/css" scoped>
@keyframes ldio-idanrvjiwra {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-idanrvjiwra div {
  left: 120.78px;
  top: 76.86px;
  position: absolute;
  animation: ldio-idanrvjiwra linear 0.9615384615384615s infinite;
  background: #f9ae5c;
  width: 2.44px;
  height: 12.2px;
  border-radius: 1.22px / 6.1px;
  transform-origin: 1.22px 45.14px;
}.ldio-idanrvjiwra div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9410801963993453s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(2) {
  transform: rotate(7.659574468085107deg);
  animation-delay: -0.9206219312602291s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(3) {
  transform: rotate(15.319148936170214deg);
  animation-delay: -0.9001636661211129s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(4) {
  transform: rotate(22.97872340425532deg);
  animation-delay: -0.8797054009819967s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(5) {
  transform: rotate(30.638297872340427deg);
  animation-delay: -0.8592471358428805s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(6) {
  transform: rotate(38.297872340425535deg);
  animation-delay: -0.8387888707037643s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(7) {
  transform: rotate(45.95744680851064deg);
  animation-delay: -0.8183306055646481s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(8) {
  transform: rotate(53.61702127659574deg);
  animation-delay: -0.7978723404255319s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(9) {
  transform: rotate(61.276595744680854deg);
  animation-delay: -0.7774140752864157s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(10) {
  transform: rotate(68.93617021276596deg);
  animation-delay: -0.7569558101472995s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(11) {
  transform: rotate(76.59574468085107deg);
  animation-delay: -0.7364975450081833s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(12) {
  transform: rotate(84.25531914893617deg);
  animation-delay: -0.716039279869067s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(13) {
  transform: rotate(91.91489361702128deg);
  animation-delay: -0.6955810147299508s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(14) {
  transform: rotate(99.57446808510639deg);
  animation-delay: -0.6751227495908346s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(15) {
  transform: rotate(107.23404255319149deg);
  animation-delay: -0.6546644844517184s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(16) {
  transform: rotate(114.8936170212766deg);
  animation-delay: -0.6342062193126022s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(17) {
  transform: rotate(122.55319148936171deg);
  animation-delay: -0.613747954173486s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(18) {
  transform: rotate(130.2127659574468deg);
  animation-delay: -0.5932896890343698s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(19) {
  transform: rotate(137.87234042553192deg);
  animation-delay: -0.5728314238952537s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(20) {
  transform: rotate(145.53191489361703deg);
  animation-delay: -0.5523731587561375s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(21) {
  transform: rotate(153.19148936170214deg);
  animation-delay: -0.5319148936170213s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(22) {
  transform: rotate(160.85106382978722deg);
  animation-delay: -0.5114566284779051s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(23) {
  transform: rotate(168.51063829787233deg);
  animation-delay: -0.4909983633387888s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(24) {
  transform: rotate(176.17021276595744deg);
  animation-delay: -0.47054009819967263s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(25) {
  transform: rotate(183.82978723404256deg);
  animation-delay: -0.45008183306055644s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(26) {
  transform: rotate(191.48936170212767deg);
  animation-delay: -0.42962356792144024s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(27) {
  transform: rotate(199.14893617021278deg);
  animation-delay: -0.40916530278232405s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(28) {
  transform: rotate(206.80851063829786deg);
  animation-delay: -0.38870703764320785s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(29) {
  transform: rotate(214.46808510638297deg);
  animation-delay: -0.36824877250409166s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(30) {
  transform: rotate(222.12765957446808deg);
  animation-delay: -0.3477905073649754s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(31) {
  transform: rotate(229.7872340425532deg);
  animation-delay: -0.3273322422258592s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(32) {
  transform: rotate(237.4468085106383deg);
  animation-delay: -0.306873977086743s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(33) {
  transform: rotate(245.10638297872342deg);
  animation-delay: -0.2864157119476268s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(34) {
  transform: rotate(252.7659574468085deg);
  animation-delay: -0.26595744680851063s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(35) {
  transform: rotate(260.4255319148936deg);
  animation-delay: -0.2454991816693944s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(36) {
  transform: rotate(268.0851063829787deg);
  animation-delay: -0.22504091653027822s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(37) {
  transform: rotate(275.74468085106383deg);
  animation-delay: -0.20458265139116202s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(38) {
  transform: rotate(283.40425531914894deg);
  animation-delay: -0.18412438625204583s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(39) {
  transform: rotate(291.06382978723406deg);
  animation-delay: -0.1636661211129296s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(40) {
  transform: rotate(298.72340425531917deg);
  animation-delay: -0.1432078559738134s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(41) {
  transform: rotate(306.3829787234043deg);
  animation-delay: -0.1227495908346972s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(42) {
  transform: rotate(314.0425531914894deg);
  animation-delay: -0.10229132569558101s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(43) {
  transform: rotate(321.70212765957444deg);
  animation-delay: -0.0818330605564648s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(44) {
  transform: rotate(329.36170212765956deg);
  animation-delay: -0.0613747954173486s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(45) {
  transform: rotate(337.02127659574467deg);
  animation-delay: -0.0409165302782324s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(46) {
  transform: rotate(344.6808510638298deg);
  animation-delay: -0.0204582651391162s;
  background: #f9ae5c;
}.ldio-idanrvjiwra div:nth-child(47) {
  transform: rotate(352.3404255319149deg);
  animation-delay: 0s;
  background: #f9ae5c;
}
.loadingio-spinner-spinner-yzwtdxlov3 {
  width: 244px;
  height: 244px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-idanrvjiwra {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-idanrvjiwra div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>