<template>
    <div id="donate-page">
        <div id="donate-header">
            <ExplorePageTitles title="Donate"/>
        </div>
        <div id="donate-page-spinner" v-show="loading">
            <Spinner />
        </div>
        <div id="donate-page-content" v-show="!loading">
            <div id="main-donate">
                <div id="dbox-container">
                    <iframe src="https://donorbox.org/embed/calgary-animal-save?default_interval=o" 
                        name="donorbox"
                        id="donorbox"
                        allowpaymentrequest="allowpaymentrequest" 
                        seamless="seamless" 
                        frameborder="0" 
                        scrolling="no" 
                        height="100%"
                        width="100%" 
                        style="max-width: 380px; min-width: 200px; max-height:none!important"
                        z-index="99">
                    </iframe>
                </div>
                <div id="donate-info-container">
                    <h3>Your gift will help advance Calgary Animal Save's mission in the following ways:</h3>
                    <div id="vector-grid">
                        <div class="vector-container">
                            <img class="donate-vector-images" src="@/assets/vector-camp.png" alt="Bullseye vector">
                            <p class="donate-vector-tagline">Campaigning</p>
                        </div>
                        <div class="vector-container">
                            <img class="donate-vector-images" src="@/assets/vector-lobby.png" alt="Handshake vector">
                            <p class="donate-vector-tagline">Lobbying</p>
                        </div>
                        <div class="vector-container">
                            <img class="donate-vector-images" src="@/assets/vector-edu.png" alt="Conversation vector">
                            <p class="donate-vector-tagline">Education & Publicity</p>
                        </div>
                        <div class="vector-container">
                            <img class="donate-vector-images" src="@/assets/vector-act.png" alt="Activism vector">
                            <p class="donate-vector-tagline vector-lrg-text">Street Activism & Community Building</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="merch-link">
                <img
                    @click="$router.push('/shop')"
                    src="@/assets/merch-image.png" 
                    alt="Merchandise image"
                >
            </div>
        </div>
    </div>
</template>

<script>
import ExplorePageTitles from "../components/ExplorePageTitles.vue"
import Spinner from '../components/Spinner.vue'

    export default {
        name: "Donate",
        components: {
            ExplorePageTitles,
            Spinner
        },
        data() {
            return {
                loading: true,
            }
        },
        mounted() {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    }
</script>

<style lang="scss" scoped>
    #donate-page {
        width: 100vw;
        padding: 10px 20px;
        background-color: white;
        min-height: 100vh;
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        box-shadow: 0 5px 15px  rgb(131, 131, 131);
        display: grid;
        justify-items: center;
        align-items: center;

        #donate-page-content {
            display: grid;
            justify-items: center;
            align-items: center;
        }

        #donate-page-spinner {
            height: 100vh;
        }

        #donate-header {
            font-size: 2em;
            margin-bottom: 40px;
        }

        #main-donate {
            width: 100%;
            display: grid;
            justify-items: center;
            margin-bottom: 40px;

            #dbox-container {
                height: 700px;
            }

            #donate-info-container {
                display: grid;
                justify-items: center;
                
                h3 {
                    text-align: center;
                    font-family: "Helvetica";
                    margin-bottom: 10px;
                }

                #vector-grid {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    justify-items: center;

                    .vector-container {
                        width: 100%;
                        display: grid;
                        justify-items: center;
                        align-items: end;
                        text-align: center;
                        .donate-vector-images {
                            width: 100%;
                            max-width: 200px;
                        }

                        .donate-vector-tagline {
                            font-family: "Helvetica";
                        }

                        .vector-lrg-text {
                            margin: 0;
                        }
                    }
                }
            }
        }
        #merch-link {
            width: 100%;
            max-width: 700px;
            justify-self: center;
            display: grid;
            background-color: black;
            margin: 30px 0;
            border-radius: 8px;
            overflow: hidden;

            img {
                width: 100%;
                max-width: 700px;
                justify-self: center;
                transition: all 0.3s  ease-in;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                    transform: scale(1.02);
                }
            }
        }
    }

@media screen and (min-width: 600px) {
    #donate-page {
        margin-bottom: 150px; //account for absolute footer height
        padding: 10px 30px;
    }
}

@media screen and (min-width: 1000px) {
    #donate-page {
        max-width: 1350px;
        min-height: calc(100vh - 230px);
        margin-top: 80px; //account for fixed nav-bar height
        display: grid;
        padding: 10px 60px;

        #main-donate {
            grid-template-columns: 50% 50%;
            grid-template-rows: auto;
            align-items: center;

            #donate-info-container {
                grid-column: 1;
                grid-row: 1;
                margin-bottom: 60px;

                h3 {
                    margin-bottom: 30px;
                    padding: 0 70px;
                }
            }

            #dbox-container {
                height: 800px;

                #donorbox {
                    align-self: center;
                    grid-column: 2;
                    grid-row: 1;
                    margin-top: 100px;
                }
            }

        }
    }
}
</style>